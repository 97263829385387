
// /components/layouts/layout.tsx
import Header from "@/components/layouts/header/header";
import { ReactNode } from "react";

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="relative flex-grow">{children}</main>
    </div>
  );
};

export default Layout;
