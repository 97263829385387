import { ROUTES } from "@/lib/routes";
import Logo from "@/components/ui/logo";
import Link from "next/link";
import { FiInstagram, FiLinkedin } from "react-icons/fi";
import { IoLogoSnapchat } from "react-icons/io";

export default function Footer() {
  return (
    <footer className="flex flex-col border-b-2 border-gray-200 rounded-b-lg justify-center items-start max-w-7xl mx-auto px-4 w-full mb-">
      <div className="w-full grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3   gap-5 md:gap-9 lg:gap-x-8 xl:gap-5  pb-8 md:pb-10 lg:pb-10">
        <div className="max-sm:col-span-2">
          <div className="footer-container">
            <div className="footerMe">
              <div>
                <Logo />
              </div>

              <p className="pt-2 text-lg text-gray-500 placeholder:text-gray-300 dark:text-gray-400">
                Tech enthusiast, Prospective Reliability Engineer,
                <br />
                Sys. Admin.
              </p>
              <div className="social_icons flex gap-2 mt-2 items-center">
                <Link href="https://instagram.com/yhmiller" target="_blank" aria-label="social_icon">
                  <FiInstagram className="text-gray-600 dark:text-gray-100" />
                </Link>
                <Link
                  href="https://www.snapchat.com/add/snr.mill?locale=en-GB"
                  target="_blank"
                  aria-label="social_icon"
                >
                  <IoLogoSnapchat className="text-gray-600 dark:text-gray-100" />
                </Link>
                <Link href="https://www.linkedin.com/in/yhmiller/" target="_blank" aria-label="social_icon">
                  <FiLinkedin className="text-gray-600 dark:text-gray-100" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        
        <div className="col-start-3 col-span-1">
          <div>
            <nav>
              <p className="text-lg font-semibold">Tools:</p>
              <ul className="space-y-2 text-sm">
                <li>
                  <Link 
                    href={`${ROUTES.TOOLS}/investment-calculator`}
                    className="text-gray-600 dark:text-gray-100 hover:text-blue-500">
                    Investment Calculator
                  </Link>
                </li>
                
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {/* ByLine */}
      <span className="flex w-full text-sm md:text-base text-gray-400 justify-center pb-1">
        You could have been anywhere on the internet, yet you're here. Thanks
        for visiting!
      </span>
    </footer>
  );
}
