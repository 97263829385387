import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FiHelpCircle } from "react-icons/fi";

export default function Chat() {
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    email: "",
    message: "",
  });
  const [status, setStatus] = useState({ success: "", error: "" });
  const [loading, setLoading] = useState(false);

  const dropIn = {
    hidden: { y: "4vh", opacity: 0 },
    visible: {
      y: "0",
      opacity: 1,
      transition: { duration: 0.2, type: "stiff", damping: 25 },
    },
    exit: { y: "4vh", opacity: 0 },
  };

  const handleChange = (field, value) => {
    setFormState((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleSubmit = async () => {
    if (!formState.email || !formState.message) {
      setStatus({ ...status, error: "Please fill in all fields." });
      return;
    }

    setLoading(true);
    try {
      const res = await fetch("/api/sendEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: "hi@princemiller.com", // Email to receive the message
          subject: "New Message from Chat Form",
          email: formState.email,
          message: formState.message,
        }),
      });

      const data = await res.json();
      if (res.ok) {
        setStatus({ success: "Submitted successfully! 🎉", error: "" });
        setFormState({ email: "", message: "" });
      } else {
        setStatus({ success: "", error: data.error || "Failed to send email." });
      }
    } catch (error) {
      setStatus({ success: "", error: "Failed to send email." });
    } finally {
      setLoading(false);
    }
  };

  const toggleChat = () => {
    setOpen((prevState) => !prevState);
    setStatus({ success: "", error: "" });
    setFormState({ email: "", message: "" });
    setLoading(false);
  };

  return (
    <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
      <div className="fixed right-4 md:right-10 bottom-10 flex flex-col items-end z-20">
        {open && (
          <motion.div
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="mb-4 rounded-xl shadow-2xl bg-white dark:bg-gray-700 flex flex-col overflow-hidden mx-4 md:mx-0"
          >
            <div className="p-4 bg-gray-100 dark:bg-gray-700">
              <h2 className="text-gray-700 font-bold text-xl dark:text-white">
                Got a question? 🤔
              </h2>
              <small className="text-xs text-gray-500  dark:text-gray-400">
                It won't take more than 10 seconds. Shoot your shot! 😉
              </small>
            </div>

            <div className="content p-6 flex flex-col">
              <label className="text-sm font-normal text-black mb-2 dark:text-white">
                Email Address
              </label>
              <input
                type="email"
                value={formState.email}
                onChange={(e) => handleChange("email", e.target.value)}
                className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-transparent dark:border-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500"
                placeholder="name@example.com"
              />

              <label className="text-sm font-normal text-black mb-2 dark:text-white">
                Message
              </label>
              <textarea
                rows="5"
                value={formState.message}
                onChange={(e) => handleChange("message", e.target.value)}
                className="text-gray-700 rounded-md border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-400 placeholder:text-sm dark:bg-gray-700 dark:border-gray-400 dark:text-white mb-4"
                placeholder="I'd love a compliment from you."
              />

              <button
                onClick={handleSubmit}
                disabled={loading}
                className="w-full text-gray-900 bg-white border border-gray-400 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium transition rounded text-sm px-5 py-2.5 mb-2 dark:bg-gray-700 dark:text-white dark:border-gray-400 dark:hover:bg-gray-500 dark:focus:ring-gray-700"
              >
                {loading ? "Submitting..." : "Submit"}
              </button>

              {status.success && (
                <p className="text-green-500 font-semibold text-sm">{status.success}</p>
              )}
              {status.error && (
                <p className="text-red-400 font-normal text-sm">{status.error}</p>
              )}
            </div>
          </motion.div>
        )}

        <button
          aria-label="chat"
          onClick={toggleChat}
          className="bg-gray-100 dark:bg-black w-14 h-14 rounded-full flex items-center justify-center hover:scale-105 hover:shadow-xl transition duration-200 shadow-lg"
        >
          <FiHelpCircle className="w-6 h-6 text-gray-600 dark:text-white" />
        </button>
      </div>
    </AnimatePresence>
  );
}
