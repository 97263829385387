// /pages/_app.tsx

import { AppProps } from "next/app";
import { ThemeProvider } from "next-themes";
import Script from "next/script";
import { DefaultSeo } from "next-seo";
import SEOConfig from "@/next-seo.config";
import { Analytics } from '@vercel/analytics/react';
// import { InstantPageLayout } from '@/components/layouts/InstantPageLayout';
import Layout from "@/components/layouts/layout";
import Chat from "@/components/Chat";
import Footer from "@/components/layouts/footer/footer";
import "@/styles/globals.css";
import { useRouter } from "next/router";
import { GA_TRACKING_ID } from "@/lib/gtag";
import Head from 'next/head';

export default function CustomApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const url = `https://princemiller.com${router.asPath}`;

  return (
    <ThemeProvider attribute="class">
      {/* <InstantPageLayout> */}
        <Layout>
          <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          </Head>
            <Script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
            <Script
              id="gtag-init"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${GA_TRACKING_ID}', {
                    page_path: window.location.pathname,
                  });
                `,
              }}
            />
          <DefaultSeo {...SEOConfig} />
          <Component {...pageProps} />
          
          <Chat />
          <Footer />
          <Analytics />
        </Layout>
      {/* </InstantPageLayout> */}
    </ThemeProvider>
  );
}
