import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useTheme } from "next-themes";
import Link from "@/components/ui/link";
import Logo from "@/components/ui/logo";
import { ROUTES } from "@/lib/routes";

const Header = () => {
  const [mounted, setMounted] = useState(false);
  const [scrolled, setScrolled] = useState(false); // State to track scroll position
  const { theme, setTheme } = useTheme();
  const router = useRouter();

  useEffect(() => {
    setMounted(true);

    // Add scroll event listener
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true); // Set to true when scrolled
      } else {
        setScrolled(false); // Reset to false when at the top
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <header
      className={` inset-x-0 top-0 z-50 w-screen max-w-full transition-all duration-300 ease-in-out  ${
        scrolled ? "bg-none " : "bg-transparent"
      }`}
    >
      <nav className="group bg-white relative w-full justify-center px-0  transition-all duration-300 ease-in-out [&&]:lg:mx-auto [&&]:lg:mt-4 [&&]:lg:w-[calc(100%-40px)] [&&]:lg:max-w-[1920px] [&&]:lg:rounded-lg [&&]:lg:overflow-hidden [&&]:lg:shadow-lg">
        <div className="relative flex justify-between items-center py-2 px-3 md:p-2 shadow-sm">

          <div className="flex h-[45px] w-[45px] cursor-pointer flex-col justify-center rounded-xl border-none p-[10px] transition-colors duration-300">
            <Logo />
          </div>

          <div className="flex space-x-2 items-center text-base md:text-lg">
            <div className="mr-auto flex flex-1 gap-2 items-center text-gray-600 hover:text-gray-800">
              <Link href={ROUTES.ABOUT} className="">
                about
              </Link>
              <Link href={ROUTES.WORKS} className="">
                works
              </Link>
              <Link href={ROUTES.RESUME} className="">
                resume
              </Link>
            </div>
            <div className="mr-auto w-full flex-1 justify-end lg:flex">
              {/* Day/Night Mode */}
              <button
                aria-label="Toggle Dark Mode"
                type="button"
                className="text-sm bg-gray-800 md:me-0 ml-6 justify-between focus:ring-4 focus:ring-gray-300 rounded-full bg-white/90 px-3 py-2 shadow-sm shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-900/90 dark:ring-gray-500/50 dark:hover:ring-white/20"
                onClick={toggleTheme}
              >
                {mounted && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    stroke="currentColor"
                    className="h-4 w-4 text-gray-800 dark:text-gray-500"
                  >
                    {theme === "dark" ? (
                      <path d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                    ) : (
                      <path d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                    )}
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
