export const ROUTES = {
    HOME: '/',
    ABOUT: '/about',
    WORKS: '/works',
    // PROJECTS: '/projects',
    RESUME: 'https://drive.google.com/file/d/1XSb8WJXD8_w1jXmrG8t4t-VeA-W8z3YV/view',
    FRIDAY: '/friday',
    TOOLS: '/tools',
    PLAYGROUND: '/playground',
    
  };
