// /next-seo.config.ts
import { DefaultSeoProps } from "next-seo";

const config: DefaultSeoProps = {
  titleTemplate: "%s | Prince Miller",
  defaultTitle: "Prince Miller - Software Engineer",
  description: "Personal website and blog of Prince Miller, a software engineer specializing in web development.",
  
  openGraph: {
    type: "website",
    locale: "en_US",
    url: "https://princemiller.com/",
    siteName: "Prince Miller",
    images: [
      {
        url: "https://princemiller.com/og-image.jpg",
        width: 1200,
        height: 630,
        alt: "Prince Miller",
      },
    ],
  },
  twitter: {
    handle: "@princemiller",
    site: "@princemiller",
    cardType: "summary_large_image",
  },
  additionalMetaTags: [
    {
      name: "keywords",
      content: "software engineer, web development, react, nextjs, typescript",
    },
    {
      name: "author",
      content: "Prince Miller",
    },
  ],
  additionalLinkTags: [
    {
      rel: "icon",
      href: "/favicon.ico",
    },
  ],
};

export default config;
